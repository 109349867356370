import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import GoogleAdsense from "../components/GoogleAdsense"

export default function Poem({ data }) {
  const { contentfulPoem } = data

  return (
    <Layout>
      <Seo
        title={`bài thơ: ${contentfulPoem.title} - ${contentfulPoem.poet.penName}`}
        description={contentfulPoem.childContentfulPoemContentTextNode.childMarkdownRemark.excerpt.replace(
          /[\u2800]/g,
          ""
        )}
        keywords={[contentfulPoem.poet.penName, contentfulPoem.title]}
      />
      <div className="flex flex-col space-y-12">
        <div className="pb-3">
          <h1 className="font-alegreya font-medium text-2xl sm:text-3xl">
            {contentfulPoem.title}
          </h1>
          <h2 className="text-lg sm:text-xl font-medium text-gray-500 pt-2">
            <Link to={`/poets/${contentfulPoem.poet.slug}`}>
              {contentfulPoem.poet.penName}
            </Link>
          </h2>
        </div>
        <div
          className="font-alegreya whitespace-pre-wrap"
          dangerouslySetInnerHTML={{
            __html:
              contentfulPoem.childContentfulPoemContentTextNode
                .childMarkdownRemark.html,
          }}
        />
        {contentfulPoem.childContentfulPoemOtherInfoTextNode ? (
          <div>
            <p className="font-medium mb-2">Bên lề:</p>
            <div
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{
                __html:
                  contentfulPoem.childContentfulPoemOtherInfoTextNode
                    .childMarkdownRemark.html,
              }}
            />
          </div>
        ) : null}
        <p className="text-gray-400 text-base sm:text-md pt-2 italic">
          Đăng trên{" "}
          <a
            href="https://instagram.com/thobuon"
            target="_blank"
            rel="noreferrer"
          >
            @thobuon
          </a>{" "}
          ngày {contentfulPoem.publishedDate}
        </p>
      </div>
      <GoogleAdsense
        client="ca-pub-1804168523618722"
        slot="3767743010"
        className="google-ads-footer"
      />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    contentfulPoem(id: { eq: $id }) {
      title
      childContentfulPoemContentTextNode {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 155, format: PLAIN, truncate: true)
        }
      }
      childContentfulPoemOtherInfoTextNode {
        childMarkdownRemark {
          html
        }
      }
      poet {
        slug
        penName
      }
      publishedDate(formatString: "DD/MM/YYYY")
    }
  }
`
